import {Store} from 'store/Base';
import {observable} from 'mobx';
import {Category} from "./Category";
import {PhaseStore} from "./Phase";
import {Casts} from "./Base";
import {User} from "./User";
import { Template } from './Template';
import { TreatmentClinicalPictureStore } from "./ClinicalPicture/TreatmentClinicalPicture";
import { DndRootModel } from "../spider/store/DndAccordion";
import { TreatmentTreatmentGroupStore } from "./TreatmentCustomerLink/TreatmentTreatmentGroup";
import { CustomerGroup } from "./TreatmentCustomerLink/CustomerGroup";
import { TreatmentMedicationScheduleStore } from "./MedicationSchedule/TreatmentMedicationSchedule";

export const TREATMENT_TYPE_TREATMENT = 'treatment';
export const TREATMENT_TYPE_PATH = 'path'

export const TYPES = [
    TREATMENT_TYPE_TREATMENT,
    TREATMENT_TYPE_PATH,
];

export class Treatment extends DndRootModel {

    static backendResourceName = 'treatment';

    @observable id = null;
    @observable name = '';
    @observable abbreviation = '';
    @observable lastUpdatedAt = null;
    @observable icon = ''

    @observable level = 'grandmother';
    @observable published = false;
    @observable numUsers = -1;
    @observable numCopies = -1;
    @observable isHealthbaseActive = false;

    @observable type = TREATMENT_TYPE_TREATMENT
    @observable isDefaultPath = false;

    @observable ziNumber = null;
    @observable parallelZiNumbers = [];
    @observable hpkCode = null;
    @observable atcCode = null;
    @observable rvgNumber = null;
    @observable packageInsertLink = null;

    @observable translationStatus = undefined;

    get _children(){
        return this.phases;
    }

    get treatmentInfo() {
        return this.treatmentClinicalPictures
    }

    relations() {
        return {
            category: Category,
            phases: PhaseStore,
            lastUpdatedBy: User,
            parent: Treatment,
            copies: TreatmentStore,
            treatmentClinicalPictures: TreatmentClinicalPictureStore,
            treatmentTreatmentGroups: TreatmentTreatmentGroupStore,
            treatmentMedicationSchedules: TreatmentMedicationScheduleStore,
            customerGroup: CustomerGroup,
            template: Template
        };
    }


    casts() {
        return {
            lastUpdatedAt: Casts.datetime
        };
    }

    /**
     * Returns a list of phases from the treatments that are children of the current treatment that this moment is
     * attached to.
     *
     * @param model     - The moment to get the relatedChildPhases for.
     * @returns {*|Promise<any>}
     */
    getRelatedPhases = (model) =>{
        const params = {moment: model.id}
        const promise = this.api.get(`treatment/${this.id}/child_phases/`, params)
            .then((res)=>{
                return res['related_phases']
            })
            .catch((err)=>{
                console.log(err)
            })
        return promise
    }



}

export class TreatmentStore extends Store {
    Model = Treatment;
    static backendResourceName = 'treatment';
}
