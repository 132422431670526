import PropTypes from 'prop-types';
import React, {Component} from 'react';
import View from 'spider/store/View';
import {Route as BaseRoute, Switch, Redirect} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import Load from './Loadable';

const QuestionnaireOverviewScreen = Load(() => import("../screen/Library/Questionnaire/Overview"));
const QuestionnaireEditScreen =  Load(() => import("../screen/Library/Questionnaire/Edit"));
const QuestionnaireDefineScreen = Load(() => import("../screen/Library/Questionnaire/Define"));

const ProgressMeterOverviewScreen = Load(() => import("../screen/Library/ProgressMeter/Overview"));

const MedicationOverviewScreen = Load(() => import("../screen/Library/ClinicalPicture/medication/Overview"));
const IndicationOverviewScreen = Load(() => import("../screen/Library/ClinicalPicture/indication/Overview"));
// const MedicationEditScreen = Load(() => import("../screen/Library/ClinicalPicture/medication/EditModal"));
// const IndicationEditScreen = Load(() => import("../screen/Library/ClinicalPicture/indication/EditModal"));
const MedicationScheduleOverviewScreen = Load(() => import("../screen/Library/MedicationSchedule/Overview"));
const ClinicalPictureOverviewScreen = Load(() => import("../screen/Library/ClinicalPicture/Overview"));
//const ClinicalPictureEditScreen =  Load(() => import("../screen/Library/ClinicalPicture/EditModal"));
const ClinicalPictureDefineScreen = Load(() => import("../screen/Library/ClinicalPicture/Define"));
const MedicationScheduleEditScreen = Load(() => import("../screen/Library/MedicationSchedule/Edit"))

const ImageOverviewScreen = Load(() => import("../screen/Library/Image/Overview"));
const ImageEditScreen = Load(() => import("../screen/Library/Image/Edit"));

const CategoryOverviewScreen = Load(() => import("../screen/Category/Overview"));
const CategoryEditScreen = Load(() => import("../screen/Category/Edit"));

const CmsPageOverview = Load(() => import('../screen/CMS/PageOverview'));
const CmsVersionOverview = Load(() => import('../screen/CMS/VersionOverview'));
const CmsVersionEdit = Load(() => import('../screen/CMS/VersionEdit'));

const TreatmentOverviewScreen = Load(() => import("../screen/Treatment/Overview"));
const TreatmentEditScreen = Load(() => import("../screen/Treatment/Edit"));
const TreatmentActiveUsers = Load(() => import('../screen/Treatment/ActiveUsers'));
const TreatmentTimelineScreen = Load(() => import('screen/Treatment/Treatment'));
const MedicationInformationScreen = Load(() => import("../screen/Treatment/MedicationInformation"));
const MomentEditScreen = Load( () => import('../screen/Treatment/MomentEdit'));
const TreatmentPreviewScreen = Load(() => import('../screen/Treatment/Preview/Preview'));

const PathOverviewScreen = Load(() => import('../screen/GenericMoments/Overview'));
const PathTimelineScreen = Load(() => import('../screen/GenericMoments/GenericMoment'))

const TreatmentGroupOverview = Load( () => import('../screen/Treatment/TreatmentGroup/Overview'))
const TreatmentGroupEdit = Load( () => import('../screen/Treatment/TreatmentGroup/Edit'))

// Use the sentry route to enable parameter discovery by react router before
// sending that information to sentry
const Route = Sentry.withSentryRouting(BaseRoute);

const UserOverview = Load(() => import('../screen/User/Overview'));
const UserEdit = Load(() => import('../screen/User/Edit'));
const CustomerOverview = Load(() => import('../screen/Customer/Overview'));
const CustomerEdit = Load(() => import('../screen/Customer/Edit'));
const CustomerGroupOverview = Load(() => import('../screen/Customer/CustomerGroup/Overview'))
const CustomerGroupEdit = Load(() => import('../screen/Customer/CustomerGroup/Edit'))

const TreatmentCustomerLinkOverview = Load(() => import('../screen/TreatmentCustomerLink/Overview'))

const AccessLogEntryOverview = Load(() => import('../screen/AccessLogEntry/Overview'));
const EmailLogOverview = Load(() => import('../screen/User/EmailLog'));

const LoginForgot = Load(() => import('../screen/Login/LoginForgot'));
const ResetPassword = Load(() => import('../screen/Login/ResetPassword'));
const AccountDetails = Load(() => import('../screen/AccountDetails'));
const Changelog = Load(() => import('../screen/Changelog'));

const CommunicationSubscription = Load(() => import('../screen/Subscription/CommunicationSubscription'));

const TemplateOverview = Load(() => import('../screen/Template/Overview'));
const TemplateEdit = Load(() => import('../screen/Template/Edit'));


const PatientOverview = Load(() => import('../screen/Patient/Overview'));
const PatientEdit = Load(() => import('../screen/Patient/Edit'));
const PatientImport = Load(() => import('../screen/Patient/Import'));
const SelfOnboarding = Load(() => import('../screen/Patient/SelfOnboarding'));
const PatientTreatmentEdit = Load(() => import('../screen/Patient/PatientTreatmentEdit'));
const PatientTreatmentAddBulk = Load(() => import('../screen/Patient/PatientTreatmentAddBulk'));

const PharmacistOverview = Load(() => import('../screen/Pharmacist/Overview'));
const PharmacistEdit = Load(() => import('../screen/Pharmacist/Edit'));


const CheckContentOverview = Load(() => import('../screen/CheckContent/Overview'));
const DocumentReviewEdit = Load(() => import('../screen/DocumentReview/Edit'))

const Report = Load(() => import("../screen/Report"));


const NotFound = Load(() => import('../container/NotFound'));

export default class Router extends Component {
    static propTypes = {
        store: PropTypes.instanceOf(View).isRequired,
    };

    // react-router is a bit too verbose so I made a shorthand
    route = Screen => {
        return rProps => <Screen {...rProps} viewStore={this.props.store}/>;
    };

    redirectFromHome = () => {
        const {currentUser} = this.props.store;
        let uri = currentUser.isSuperuser ? (
            '/assets/user/overview'
        ) : (currentUser.inGroup('pharmacist') ? (
            '/patient/patient/overview'
        ) : (
            '/workorder/template/overview'
        ));

        return <Redirect to={uri}/>;
    };

    render() {
        return (
            <Switch>
                <Route path="/" exact render={this.redirectFromHome}/>

                <Route path="/assets/user/overview" render={this.route(UserOverview)}/>
                <Route path="/assets/user/:id/edit" render={this.route(UserEdit)}/>
                <Route path="/assets/user/add" render={this.route(UserEdit)}/>
                <Route path="/assets/user/email-log/overview" render={this.route(EmailLogOverview)}/>

                <Route path="/assets/customer/overview" render={this.route(CustomerOverview)} />
                <Route path="/assets/customer/:id/edit" render={this.route(CustomerEdit)} />
                <Route path="/assets/customer/:id/edit/:page" render={this.route(CustomerEdit)} />
                <Route path="/assets/customer/add" render={this.route(CustomerEdit)} />

                <Route path="/assets/customergroup/overview" render={this.route(CustomerGroupOverview)} />
                <Route path="/assets/customergroup/:id/edit" render={this.route(CustomerGroupEdit)} />

                <Route path="/assets/treatmentcustomerlink/overview" render={this.route(TreatmentCustomerLinkOverview)} />

                <Route path="/audit/access-log/overview" render={this.route(AccessLogEntryOverview)}/>

                <Route path="/account/details" render={this.route(AccountDetails)}/>
                <Route path="/account/changelog" render={this.route(Changelog)}/>
                <Route path="/login/forgot" render={this.route(LoginForgot)}/>
                <Route path="/user/:id/reset-password/:token" render={this.route(ResetPassword)}/>
                <Route path="/subscription/communication/:token" render={this.route(CommunicationSubscription)}/>

                <Route path="/workorder/template/overview" render={this.route(TemplateOverview)}/>
                <Route path="/workorder/template/add" render={this.route(TemplateEdit)} />

                <Route path="/workorder/check-content/overview" render={this.route(CheckContentOverview)}/>

                <Route path="/workorder/template/:id/review" render={this.route(DocumentReviewEdit)} />

                <Route path="/workorder/:id/preview/:screen/:cpid/:cpiid" render={this.route(TreatmentPreviewScreen)} />
                <Route path="/workorder/:id/overview/:screen/:cpid/:cpiid" render={this.route(TreatmentPreviewScreen)} />
                <Route path="/workorder/:id/preview/:screen" render={this.route(TreatmentPreviewScreen)} />
                <Route path="/workorder/:id/preview" render={this.route(TreatmentPreviewScreen)} />



                {/* The following route needs to come before /treatment/:id/overview*/}
                <Route path="/treatment/genericmoment/overview" render={this.route(PathOverviewScreen)}/>
                <Route path="/treatment/genericmoment/:id/overview/:screen" render={this.route(PathTimelineScreen)} />
                {/*  Timeline screen defaults to timeline unless specified differently   */}
                <Route path="/treatment/genericmoment/:id/overview" render={this.route(PathTimelineScreen)} />

                <Route path="/treatment/category/overview" render={this.route(CategoryOverviewScreen)} />
                <Route path="/treatment/category/add" render={this.route(CategoryEditScreen)} />
                <Route path="/treatment/category/:id/edit" render={this.route(CategoryEditScreen)} />

                <Route path="/treatment/page/overview" render={this.route(CmsPageOverview)}/>
                <Route path="/treatment/page/:id/version/overview" render={this.route(CmsVersionOverview)}/>
                <Route path="/treatment/page/:pageId/version/add" render={this.route(CmsVersionEdit)}/>
                <Route path="/treatment/page/:pageId/version/:id/edit" render={this.route(CmsVersionEdit)}/>

                <Route path="/treatment/overview" render={this.route(TreatmentOverviewScreen)} />
                <Route path="/treatment/add" render={this.route(TreatmentEditScreen)} />
                {/*<Route path="/treatment/:id/edit" render={this.route(TreatmentEditScreen)} />*/}
                <Route path="/treatment/usedBy" render={this.route(TreatmentActiveUsers)} />
                <Route path="/treatment/:id/overview/:screen/:cpid/:cpiid" render={this.route(TreatmentTimelineScreen)} />
                <Route path="/treatment/:id/overview/:screen" render={this.route(TreatmentTimelineScreen)} />
                {/*  Timeline screen defaults to timeline unless specified differently   */}
                <Route path="/treatment/:id/overview" render={this.route(TreatmentTimelineScreen)} />
                <Route path="/treatment/:id/medication_information" render={this.route(MedicationInformationScreen)} />
                <Route path="/treatment/phase/:phaseId/moment/:id/edit" render={this.route(MomentEditScreen)} />
                <Route path="/treatment/phase/:phaseId/moment/add" render={this.route(MomentEditScreen)} />

                <Route path="/treatment/library/questionnaire/overview" render={this.route(QuestionnaireOverviewScreen)} />
                <Route path="/treatment/library/questionnaire/add" render={this.route(QuestionnaireEditScreen)} />
                <Route path="/treatment/library/questionnaire/:id/edit" render={this.route(QuestionnaireEditScreen)} />
                <Route path="/treatment/library/questionnaire/:id/define" render={this.route(QuestionnaireDefineScreen)} />

                <Route path="/treatment/library/progress_meter/overview" render={this.route(ProgressMeterOverviewScreen)} />

                <Route path="/treatment/library/image/overview" render={this.route(ImageOverviewScreen)} />
                <Route path="/treatment/library/image/add" render={this.route(ImageEditScreen)} />
                <Route path="/treatment/library/image/:id/edit" render={this.route(ImageEditScreen)} />

                <Route path="/treatment/library/indication/overview" render={this.route(IndicationOverviewScreen)} />
                <Route path="/treatment/library/medication/overview" render={this.route(MedicationOverviewScreen)} />
                <Route path="/treatment/library/clinical-picture/overview" render={this.route(ClinicalPictureOverviewScreen)} />
                {/*<Route path="/treatment/library/clinical-picture/add" render={this.route(ClinicalPictureEditScreen)} />*/}
                {/*<Route path="/treatment/library/clinical-picture/:id/edit" render={this.route(ClinicalPictureEditScreen)} />*/}
                {/*<Route path="/treatment/library/indication/add" render={this.route(IndicationEditScreen)} />*/}
                {/*<Route path="/treatment/library/indication/:id/edit" render={this.route(IndicationEditScreen)} />*/}
                {/*<Route path="/treatment/library/medication/add" render={this.route(MedicationEditScreen)} />*/}
                {/*<Route path="/treatment/library/medication/:id/edit" render={this.route(MedicationEditScreen)} />*/}
                <Route path="/treatment/library/medication-schedule/overview" render={this.route(MedicationScheduleOverviewScreen)} />
                <Route path="/treatment/library/medication-schedule/:id/edit" render={this.route(MedicationScheduleEditScreen)} />
                <Route path="/treatment/library/clinical-picture/:id/define" render={this.route(ClinicalPictureDefineScreen)} />

                <Route path="/treatment/treatmentgroup/list" render={this.route(TreatmentGroupOverview)} />
                <Route path="/treatment/treatmentgroup/:id/edit" render={this.route(TreatmentGroupEdit)} />

                <Route path="/patient/patient/overview" render={this.route(PatientOverview)} />
                <Route path="/patient/patient/add" render={this.route(PatientEdit)} />
                <Route path="/patient/patient/import" render={this.route(PatientImport)} />
                <Route path="/patient/patient/:id/edit" render={this.route(PatientEdit)} />
                <Route path="/patient/patient/:patientId/patient-treatment/:id/edit" render={this.route(PatientTreatmentEdit)} />
                <Route path="/patient/patient/:patientId/patient-treatment/add" render={this.route(PatientTreatmentEdit)} />
                <Route path="/patient/patient/:patientId/patient-treatment/add-bulk" render={this.route(PatientTreatmentAddBulk)} />

                <Route path="/self-onboarding/customer/:id" render={this.route(SelfOnboarding)} />

                <Route path="/assets/pharmacist/pharmacist/overview" render={this.route(PharmacistOverview)} />
                <Route path="/assets/pharmacist/pharmacist/add" render={this.route(PharmacistEdit)} />
                <Route path="/assets/pharmacist/pharmacist/:id/edit" render={this.route(PharmacistEdit)} />

                <Route path="/report/:id/" render={this.route(Report)}/>
                <Route path="/report/" render={this.route(Report)}/>

                <Route render={this.route(NotFound)}/>
            </Switch>
        );
    }
}
