import { each, get } from 'lodash';
import { getMoneyInputProps } from 'spider/helpers';
import { t } from 'i18n';

export let TAB_TITLE_PREFIX = 'PharmiCY - ';
export let FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';

// Config set by bootstrap.
export let MAPS_API_KEY = '';
export let MAPS_API_URL = '';

export function configOverride(bootstrap) {
    MAPS_API_KEY = bootstrap.google_maps_api_key;
    MAPS_API_URL = bootstrap.google_maps_api_url;
}

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : '';

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = [569, 290]; //[410, 414];

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Also used by mobile, which has a window, but no location?
export const IS_STAGE = typeof window !== 'undefined' && window.location && window.location.href.includes('staging');

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE;

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
    return Object.keys(object).reduce((prev, element) => {
        return typeof object[element] === 'object'
            ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {});
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors) {
    const messages = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.message')) {
            messages.push(flat[key]);
        }
    });

    return messages;
}

export function parseBackendErrorCodes(errors) {
    const codes = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.code')) {
            codes.push(flat[key]);
        }
    });

    return codes;
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const SCREEN_WIDTH_PX = '1280px';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const ACTION_DELAY = 300;

export function setMoneyForBackend(value, decimals=2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    return isFinite(parsed) ? parsed * Math.pow(10, decimals) : 0;
}

const moneyFormat = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol'
});

export function formatMoney(value, decimals = 2) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    //
    // There is a small but important difference with how MoneyInput formats
    // negative numbers. The negative sign must come first, and
    // Intl.NumberFormat sets the negative sign after the € sign.
    const formatted = moneyFormat.format(value / Math.pow(10, decimals)).split(' ').join('');

    if (formatted.includes('-')) {
        return formatted[1] + formatted[0] + formatted.slice(2);
    }

    return formatted;
}

export function getMoneyForUser(value, decimals=2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');

}

// Found it weird to use money helpers on fuel surcharge, so simply
// wrap them.
export function setFuelSurchargeForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFuelSurchargeForUser(value) {
    return getMoneyForUser(value);
}

export function setFactorForBackend(value) {
    return setMoneyForBackend(value);
}

export function getFactorForUser(value) {
    return getMoneyForUser(value);
}

export function getFactorInputProps() {
    return Object.assign(getMoneyInputProps(), {
        prefix: undefined,
        suffix: '%',
    });
}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.id && !newModels.find(m => m.id === selectedModel.id)) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}

export const BOOL_OPTIONS = [
    { value: 'true', text: t('form.yes') },
    { value: undefined, text: t('form.either') },
    { value: 'false', text: t('form.no') },
];

export const COLORS = [
    { color: '#41a85f', key: 'green' },
    { color: '#ebbb12', key: 'yellow' },
    { color: '#ff7f2a', key: 'orange' },
    { color: '#f55555', key: 'red' },
    { color: '#ad52f4', key: 'purple' },
    { color: '#5555ff', key: 'blue' },
    { color: '#2f80ed', key: 'lightblue'},
    { color: '#42444e', key: 'black' },
    { color: '#BDBDBD', key: 'grey'}
]

export const COLORS_BY_KEY = {};

// eslint-disable-next-line no-unused-vars
for (const { key, color } of COLORS) {
    COLORS_BY_KEY[key] = color;
}

export function getColor(i) {
    return COLORS[i % COLORS.length].color;
}

export const PRIORITY_COLORS = {
    wishlist: '#41a85f',
    low: '#ebbb12',
    normal: '#ff7f2a',
    high: '#f55555',
    unbreak_now: '#ad52f4',
};
