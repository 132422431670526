import {Model, Store} from 'store/Base';
import {observable} from 'mobx';
import {Casts} from "./Base";
import {PatientTreatmentStore} from "./PatientTreatment";
import {Customer} from "./Customer/Customer";
import { User } from './User';
import { SignalStore } from './Signal';

export class Patient extends Model {

    static backendResourceName = 'patient';

    // Unread count is used in the chat, this is calculated and does not actually exist on the model
    static omitFields = ['unreadCount', 'lastActivityTime', 'unhandledSignalCount']

    @observable id = null;
    @observable firstName = '';
    @observable lastName = '';
    @observable email = '';
    @observable language = '';
    @observable number = '';
    @observable phone = null;

    @observable createdAt = null;
    @observable appStartDate = null;

    @observable lastActivityTime = null;
    @observable unhandledSignalCount = null;

    // Unread count is used in the chat, this is calculated and does not actually exist on the model
    @observable unreadCount = null;

    relations() {
        return {
            patientTreatments: PatientTreatmentStore,
            customer: Customer,
            user: User,
            signals: SignalStore,
        };
    }


    casts() {
        return {
            createdAt: Casts.datetime,
            appStartDate: Casts.datetime,
            lastActivityTime: Casts.datetime,
        };
    }

    resendEmail() {
        return this.api.post(`${this.url}resend_mail/`);
    }

    bulkAddPatientTreatments(treatment_ids, first_usage_date) {
        return this.api.post(`${this.url}bulk_add_patient_treatments/`,
            {
                'treatments': treatment_ids,
                'first_usage_date': first_usage_date
            });
    }


}

export class PatientStore extends Store {
    Model = Patient;
    static backendResourceName = 'patient';
}
